<template>
    <div class="report text-white">
      <div v-if="loading">
        <div class="header container-fluid">
            <div>
                <h3 class="ml-2 text-left cursor-pointer" @click="$router.push({ name : 'Dashboard' })"><font-awesome-icon icon="fa-solid fa-chevron-left"/> Report</h3>
                <p class="text-left mt-2 ml-2">Select your content to view the report.</p>
            </div>
          <hr>
        </div>
        <div class="report-content">
              <div class="container-fluid mt-4">
                  <div class="quiz d-flex mt-3">
                      <div class="card mr-3" style="width: 12.5rem;">
                            <b-skeleton-img card-img="top"></b-skeleton-img>
                            <div class="card-body text-left">
                                <div class="d-flex justify-content-between mb-2">
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                </div>
                                <div class="d-flex mb-2">
                                    <b-skeleton animation="wave" width="50%"></b-skeleton>
                                </div>
                                <b-skeleton animation="wave" width="80%"></b-skeleton>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
      <div v-else>
        <div v-if="quizEvents.length > 0">
          <div class="header container-fluid">
              <div>
                  <h3 class="ml-2 text-left cursor-pointer" @click="$router.push({ name : 'Dashboard' })"><font-awesome-icon icon="fa-solid fa-chevron-left"/> Report</h3>
                  <p class="text-left mt-2 ml-2">Select your content to view the report.</p>
              </div>
              <hr>
          </div>

          <div class="report-content">
              <div class="container-fluid mt-4">
                  <div class="quiz d-flex mt-3">
                    <div v-if="searchStat == true ">
                      <div v-if="dataSearch.length > 0" class="d-flex">
                        <div v-for="(dataQuiz, index) in dataSearch" :key="index">
                            <div v-if="dataQuiz.templateLookUp.length != 0" class="card mr-3 cursor-pointer" style="width: 12.5rem;" @click="detailReport(dataQuiz._id)">
                                <img v-if="dataQuiz.templateLookUp[0].images_cover != null && dataQuiz.templateLookUp[0].images_cover != '' && dataQuiz.templateLookUp[0].images_cover != 'undefined'" :src="dataQuiz.templateLookUp[0].images_cover" :alt="dataQuiz.templateLookUp[0].images_cover" class="card-img-top">
                                <img v-else src="@/assets/images/default-cover.png" class="card-img-top" alt="default">
                                <div class="card-body text-left">
                                  <div class="d-flex justify-content-between align-items-start">
                                    <h5 class="card-title text-truncate" :title="dataQuiz.quiz_name">{{ dataQuiz.quiz_name }}</h5>
                                    <!-- <b-dropdown size="md"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret>
                                        <template #button-content>
                                          <b-icon icon="three-dots-vertical"></b-icon>
                                        </template>
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here...</b-dropdown-item>
                                      </b-dropdown> -->
                                  </div>
                                  <!-- <div class="d-flex justify-content-start mb-2">
                                    <div class="d-flex mr-4">
                                        <img
                                          src="@/assets/images/icons/ic_play_grup_grey.svg"
                                          alt=""
                                          class="user-svg"
                                        />
                                      <p class="ml-1 text-white">{{ dataQuiz.templateLookUp[0].total_play }}</p>
                                    </div>
                                    <div class="d-flex mr-4">
                                        <img
                                          src="@/assets/images/icons/ic_question_grup_grey.svg"
                                          alt=""
                                          class="user-svg"
                                        />
                                      <p class="ml-1 text-white">{{ dataQuiz.templateLookUp[0].total_question }}</p>
                                    </div>
                                    <div class="d-flex">
                                        <img
                                          src="@/assets/images/icons/ic_clock_grup_grey.svg"
                                          alt=""
                                          class="user-svg"
                                        />
                                      <p class="ml-1 text-white">{{getMinQuiz(dataQuiz.templateLookUp[0].total_question)}} min</p>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-between align-items-center mt-3">
                                    <div class="creator-info d-flex align-items-center">
                                      <img
                                        src="@/assets/images/logo/soca-logo.png"
                                        alt=""
                                        class="ml-2 rounded-circle"
                                      />
                                      <p class="text-white ml-1">{{ dataQuiz.templateLookUp[0].author ? cutName(dataQuiz.templateLookUp[0].author) : '-'}},</p>
                                      <p class="ml-1 mb-0 text-truncate" style="font-size: 12px">{{ getDate(dataQuiz.templateLookUp[0].createdAt) }}</p>
                                    </div>
                                    <div class="d-flex align-items-end">
                                      <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start">
                                      <p class="text-white ml-1 mb-0">{{ dataQuiz.templateLookUp[0].rating ? dataQuiz.templateLookUp[0].rating : '0' }}</p>
                                    </div>
                                  </div> -->
                                  <div class="d-flex justify-content-between align-items-center">
                                      <div class="d-flex">
                                          <img
                                              src="@/assets/images/icons/ic_play_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0 mr-3">{{dataQuiz.templateLookUp[0].total_play}}</p>
                                          <img
                                              src="@/assets/images/icons/ic_question_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0">{{ dataQuiz.templateLookUp[0].total_question }}</p>
                                      </div>
                                      <div class="d-flex align-items-center">
                                          <img src="@/assets/images/icons/ic_star_outline.svg" alt="">
                                          <p class="ml-1 mb-0">{{ dataQuiz.templateLookUp[0].rating ? dataQuiz.templateLookUp[0].rating : '0' }}</p>
                                      </div>
                                  </div>
                                  <div class="mt-2">
                                      <span style="color:#8C8C8C; font-size:8px;">{{ getDate(dataQuiz.createdAt) }}</span>
                                  </div>
                                </div>
                            </div>
                            <div v-else-if="dataQuiz.creatorLookup.length != 0" class="card mr-3 cursor-pointer" style="width: 12.5rem;" @click="detailReport(dataQuiz._id)">
                                <img v-if="dataQuiz.creatorLookup[0].images_cover != null && dataQuiz.creatorLookup[0].images_cover != '' && dataQuiz.creatorLookup[0].images_cover != 'undefined'" :src="dataQuiz.creatorLookup[0].images_cover" class="card-img-top" alt="">
                                <img v-else src="@/assets/images/default-cover.png" class="card-img-top" alt="default">
                                <div class="card-body text-left">
                                  <div class="d-flex justify-content-between align-items-start">
                                    <h5 class="card-title text-truncate" :title="dataQuiz.quiz_name">{{ dataQuiz.quiz_name }}</h5>
                                    <!-- <b-dropdown size="md"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret>
                                        <template #button-content>
                                          <b-icon icon="three-dots-vertical"></b-icon>
                                        </template>
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here...</b-dropdown-item>
                                      </b-dropdown> -->
                                  </div>
                                  <div class="d-flex justify-content-between align-items-center">
                                      <div class="d-flex">
                                          <img
                                              src="@/assets/images/icons/ic_play_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0 mr-3">{{dataQuiz.creatorLookup[0].total_play}}</p>
                                          <img
                                              src="@/assets/images/icons/ic_question_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0">{{ dataQuiz.creatorLookup[0].total_question }}</p>
                                      </div>
                                  </div>
                                  <div class="d-flex align-items-center">
                                          <img src="@/assets/images/icons/ic_star_outline.svg" alt="">
                                          <p class="ml-1 mb-0">{{ dataQuiz.creatorLookup[0].rating ? dataQuiz.creatorLookup[0].rating : '0' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div v-else>
                        <p>Data not Found</p>
                      </div>
                    </div>
                    <div v-else class="d-flex">
                      <div v-for="(dataQuiz, index) in searchQuery" :key="index">
                          <div v-if="dataQuiz.templateLookUp.length != 0" class="card mr-3 cursor-pointer" style="width: 12.5rem;" @click="detailReport(dataQuiz._id)">
                              <img v-if="dataQuiz.templateLookUp[0].images_cover != null && dataQuiz.templateLookUp[0].images_cover != ''" :src="dataQuiz.templateLookUp[0].images_cover" :alt="dataQuiz.templateLookUp[0].images_cover" class="card-img-top">
                              <img v-else src="@/assets/images/default-cover.png" class="card-img-top" alt="default">
                              <div class="card-body text-left">
                                <div class="d-flex justify-content-between align-items-start">
                                  <h5 class="card-title text-truncate" :title="dataQuiz.quiz_name">{{ dataQuiz.quiz_name }}</h5>
                                  <!-- <b-dropdown size="md"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret>
                                      <template #button-content>
                                        <b-icon icon="three-dots-vertical"></b-icon>
                                      </template>
                                      <b-dropdown-item href="#">Action</b-dropdown-item>
                                      <b-dropdown-item href="#">Another action</b-dropdown-item>
                                      <b-dropdown-item href="#">Something else here...</b-dropdown-item>
                                    </b-dropdown> -->
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                      <div class="d-flex">
                                          <img
                                              src="@/assets/images/icons/ic_play_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0 mr-3">{{dataQuiz.templateLookUp[0].total_play}}</p>
                                          <img
                                              src="@/assets/images/icons/ic_question_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0">{{ dataQuiz.templateLookUp[0].total_question }}</p>
                                      </div>
                                      <div class="d-flex align-items-center">
                                          <img src="@/assets/images/icons/ic_star_outline.svg" alt="">
                                          <p class="ml-1 mb-0">{{ dataQuiz.templateLookUp[0].rating ? dataQuiz.templateLookUp[0].rating : '0' }}</p>
                                      </div>

                                  </div>
                                  <div class="mt-2">
                                        <span style="color:#8C8C8C; font-size:10px;">{{ getDate(dataQuiz.createdAt) }}</span>
                                    </div>
                              </div>
                          </div>
                          <div v-else-if="dataQuiz.creatorLookup.length != 0" class="card mr-3 cursor-pointer" style="width: 12.5rem;" @click="detailReport(dataQuiz._id)">
                              <img v-if="dataQuiz.creatorLookup[0].images_cover != null && dataQuiz.creatorLookup[0].images_cover != '' && dataQuiz.creatorLookup[0].images_cover != 'undefined'" :src="dataQuiz.creatorLookup[0].images_cover" class="card-img-top" alt="">
                              <img v-else src="@/assets/images/default-cover.png" class="card-img-top" alt="default">
                              <div class="card-body text-left">
                                <div class="d-flex justify-content-between align-items-start">
                                  <h5 class="card-title text-truncate">{{ dataQuiz.quiz_name }}</h5>
                                  <!-- <b-dropdown size="md"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret>
                                      <template #button-content>
                                        <b-icon icon="three-dots-vertical"></b-icon>
                                      </template>
                                      <b-dropdown-item href="#">Action</b-dropdown-item>
                                      <b-dropdown-item href="#">Another action</b-dropdown-item>
                                      <b-dropdown-item href="#">Something else here...</b-dropdown-item>
                                    </b-dropdown> -->
                                </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                      <div class="d-flex">
                                          <img
                                              src="@/assets/images/icons/ic_play_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0 mr-3">{{dataQuiz.creatorLookup[0].total_play}}</p>
                                          <img
                                              src="@/assets/images/icons/ic_question_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0">{{ dataQuiz.creatorLookup[0].total_question }}</p>
                                      </div>
                                      <div class="d-flex align-items-center">
                                          <img src="@/assets/images/icons/ic_star_outline.svg" alt="">
                                          <p class="ml-1 mb-0">{{ dataQuiz.creatorLookup[0].rating ? dataQuiz.creatorLookup[0].rating : '0' }}</p>
                                      </div>
                                  </div>
                                  <div class="mt-2">
                                      <span style="color:#8C8C8C; font-size:10px;">{{ getDate(dataQuiz.createdAt) }}</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div v-else>
            <div class="header container-fluid">
                <div>
                    <h3 class="ml-2 text-left">Report</h3>
                    <p class="text-left mt-2 ml-2">Select your content to view the report.</p>
                </div>
                <hr>
            </div>
            <div class="zero-report d-flex align-items-center justify-content-center" style="height:70vh;">
                <p class="mt-3 ml-2" style="color: #585859">You have no report for now.</p>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
import {getQuizEvents, getSearchLaporan} from '@/services/quiz-events/quiz-events.service'

export default {
    data(){
        return {
            filter: null,
            quizEvents: [],
            dataSearch: [],
            searchStat: false,
            loading : false,
        }
    },
    mounted(){
        this.getQuizEvents()
    },

    computed: {
        searchQuery() {
            if (this.filter) {
                return this.quizEvents.filter((item) => {
                return this.filter
                    .toLowerCase()
                    .split(" ")
                    .every(
                    (v) =>
                        item.quiz_name.toLowerCase().includes(v)
                    );
                });
            } else {
                return this.quizEvents;
            }
        },
    },

    methods : {
        getQuizEvents(){
            this.loading = true;
            let data = []
            getQuizEvents().then((response)=>{
                    if(response.status){
                        this.$vs.loading.close();
                        data = response.data;
                        data.forEach((e) => {
                            if(e.quiz_name != null){
                                this.quizEvents.push(e)
                            }
                        })
                        this.loading = false;
                    } else {
                        this.$vs.loading.close();
                        this.loading = false;
                        this.quizEvents = [];
                    }

                }).catch((err)=>{
                    this.$vs.loading.close();
                    this.loading = false;
                    console.log("data err", err)
                })
        },
        detailReport(id){
            this.$router.push({ name: 'Detail-Report', query: {event_id: id}})
        },
        cutName(item){
            const name = item.split(" ")
            return name[0]
        },
        getDate(item){
            var publish = new Date(item);

            var seconds = Math.floor((new Date() - publish) / 1000);

            var interval = seconds / 31536000;

            if (interval > 1) {
                return Math.floor(interval) + " years ago";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " months ago";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " days ago";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " hours ago";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " minutes ago";
            }
            return Math.floor(seconds) + " seconds ago";
        },

        getMinQuiz(length){

            let time = length * 30

            let min = Math.floor(time / 60);

            return min
        },

        cariLaporan(e){
        if (e.target.value != null && e.target.value != "") {
          this.searchStat = true;
          let data = { search : this.filter }
          getSearchLaporan(data)
          .then((response) => {
            this.dataSearch = response.data.data
          })
        } else {
          this.searchStat = false;
        }
        },
        setSearch(e) {
          if (e == null || e == "") {
            this.searchStat = false;
          }
    },
    }
}
</script>
<style scoped>
.header .search .form-control{
    padding: 10px 30px;
 }

 .card .card-img-top {
    width: 100%;
    height: 9.5rem;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}
.header hr{
  border: none;
  background: #2D2D2D;
}

.header label{
    position: relative;
    width: 50%;
}

.header label:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
}

.report-content .card{
    border-radius: 15px !important;
}

.report-content p{
    /* color: #8C8C8C; */
    margin: 0;
    font-size: 12px;
}

.report-content .quiz {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: auto;
}

.report-content .quiz::-webkit-scrollbar{
    display: none;
}

.creator-info{
  border-radius: 12px;
  height: 25px;
  width: 50%;
  background-color: #F1F1F1;
}
.creator-info img {
  width: 18px;
  height: 18px;
}

.icon-start{
  width: 18px;
  height: 18px;
}

.card-info{
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #F1F1F1;
}

.img-sorry{
  width : 9rem;
  height : 10rem;
}

.zero-report h1{
  font-size: 30px;
}

.zero-report h3{
  font-size: 22px;
}

.form-control{
    background-color: #222222;
    color: white;
    border-color: #383838;
}
</style>